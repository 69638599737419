import React from "react";
import NavBar from "./Home/Navbar";

function Home(){
    return (
        <>
            <NavBar />
        </>
    );
}

export default Home;
import AppRouter from "./routing/AppRouter";

function App() {
  return (
  <>
    <AppRouter />
  </>
  );
}

export default App;
